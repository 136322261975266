import { default as React } from "react"
import { graphql, Link } from "gatsby"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import DotsIcon from "~/images/dots.inline.svg"
import ContactForm from "~/components/forms/contact"
import BranchCard from "~/components/branch/card"

const ContactPage = ({ data: { page, allWpBranch: { nodes: branches } } }) => {
  return (
    <Layout>
      <Seo title={page.title} />
      <section
        style={{
          backgroundImage: `url('${page?.featuredImage?.node?.localFile?.childImageSharp?.fluid?.base64}')`,
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-44 pb-12 md:pb-0 bg-primary bg-cover bg-center overflow-hidden text-white">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full flex flex-col md:w-1/3 px-8">
              <h1
                className="text-5xl md:text-7xl text-white mb-8 md:mb-20">{page.title}
              </h1>
              <small className="text-secondary mb-10">When it Matters</small>
            </div>
            <div className="flex md:justify-end items-center w-full md:w-2/3 md:px-8">
              <ul
                className="flex list-none">
                <li
                  className="mx-4 md:mx-8">
                  <Link
                    className="pb-2 border-b border-secondary"
                    to={`/contact`}>
                    Contact
                  </Link>
                </li>
                <li
                  className="mx-4 md:mx-8">
                  <Link
                    className="pb-2 border-b border-secondary"
                    to={`/locations-atms/#branches`}>
                    Branch Locations
                  </Link>
                </li>
                <li
                  className="mx-4 md:mx-8">
                  <Link
                    className="pb-2 border-b border-secondary"
                    to={`/locations-atms/#atms`}>
                    ATM Locations
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8 mb-12">
            <div className="w-full px-8">
              <h6
                className="text-secondary mb-4">Get In Touch</h6>
              <h1 className="text-6xl text-primary">We'd love to hear from you</h1>
            </div>
          </div>
          <div className="flex flex-wrap -mx-8">
            <div className="w-full px-8">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <section className="pb-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8 mb-12">
            <div className="w-full px-8">
              <h6
                className="text-secondary mb-4">Visit Us</h6>
              <h1 className="text-6xl text-primary">Our Locations</h1>
            </div>
          </div>
          <div className="relative flex flex-wrap -mx-8">
            <DotsIcon className="absolute w-32 h-32 top-0 left-0 -mt-8 -ml-8" />
            <div className="relative w-full px-8 z-10">
              <div className="flex flex-wrap -mx-8">
                {branches.map(branch => (
                  <div
                    key={branch.databaseId}
                    className="w-full md:w-1/2 px-8 mb-12">
                    <BranchCard branch={branch} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8 mb-12">
            <div className="w-full px-8">
              <p>Kirkwood Bank &amp; Trust strives to support local organizations within our communities. Community involvement at Kirkwood Bank & Trust goes beyond financial support. We are also committed to giving back through volunteer hours which help make our communities extraordinary places to work and live. If you would like to submit a request for support for your organization, please fill out our Donation/Sponsorship Request Form. Please allow us 3 weeks to evaluate your request.</p>
              <p
                className="mt-6">
                <a
                  className="btn btn-secondary"
                  href={`/files/donation-support-request.pdf`}>
                  <span>Download Donation Request</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId }){
      title
      content
      featuredImage {
        node {
          localFile{
            childImageSharp{
              fluid(toFormatBase64: WEBP, base64Width: 2000){
                base64
              }
            }
          } 
        }
      }
    }
    allWpBranch {
      nodes {
        databaseId
        title
        branches {
          address {
            street
            city
            state
            zip
          }
          phone
          tollFree
          fax
        }
      }
    }
  }
`
export default ContactPage