/** @jsx jsx */
import {jsx} from "theme-ui"
import qs from "qs"
import { stripNumber } from "~/helpers"

const BranchCard = ({ branch: { title = '', branches: { address = {}, phone = '', tollFree, fax }}}) => {
  const link = qs.stringify({ q: Object.values(address).join(' ') })
  return (
    <div
      sx={{
        a: {
          color: 'blue.600'
        }
      }}
      className="flex flex-col h-full p-12 bg-gray-100 border-l-2 border-secondary">
      <h3 className="text-3xl text-primary mb-6">{ title }</h3>
      <div
        className="flex flex-wrap items-start -mx-4">
        <div
          className="w-full md:w-7/12 px-4 mb-8">
          <a
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
            href={`https://maps.google.com?${link}`}>
            { address.street }<br />
            { `${address.city}, ${address.state} ${address.zip}` }
          </a>
         </div>
        <div
          className="w-full md:w-5/12 px-4">
          <ul
            className="list-none p-0 m-0 leading-loose">
            <li>
              <span>p: </span>
              <a href={`tel:${stripNumber(phone)}`}>
                {phone}
              </a>
            </li>
            { tollFree && (
              <li>
                <span>tf: </span>
                <a href={`tel:${stripNumber(tollFree)}`}>
                  {tollFree}
                </a>
              </li>
            )}
            { fax && (
              <li>
                <span>f: </span>
                <span>
                  {fax}
                </span>
              </li>
            )}
          </ul>
         </div>
      </div>
    </div>
  )
}

export default BranchCard